import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  SlideTransitionWrapper,
  Banner,
  BannerBackground,
  BannerLogo,
  ContentWrapper,
  ContentWrapperWithoutPadding,
  ContentWrapperPadding,
  Title,
  Description,
  BlueWrapper,
  ArrowsWrapper,
  LeftArrowWrapper,
  RightArrowWrapper,
} from "./style";
import VileoApp from "../Vileo/Mobile";
import { PageChange } from "../../../../../common/Scroll";
import { PageThemeType } from "../../../../../../utils/types";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import ArrowLeft from "-!svg-react-loader!../../../../../../assets/images/arrowLeft.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../assets/images/arrowRight.svg";
import { PageTitle } from "../../../../../common/PageTitle";

interface CivileoProps {
  pageChange: PageChange;
  pageThemeChange?: (theme?: PageThemeType) => void;
}

const CivileoMobilePage: React.FC<CivileoProps> = ({
  pageChange,
  pageThemeChange,
}) => {
  const { t } = useTranslation("rnd");
  const { logo, background } = useStaticQuery(imagesQuery);
  let descriptions = (t(`civileo_descriptions_mobile`) as unknown) as string[];
  descriptions = descriptions.concat(
    (t(`civileo_sideDescriptions`) as unknown) as string[]
  );
  const nOfSlides = 4;
  const bluePages = [3, 4];

  const [slide, setSlide] = useState<number>(
    pageChange === "previous" ? nOfSlides : 0
  );
  const [slideTransition, setSlideTransition] = useState<PageChange>();

  useEffect(() => {
    pageThemeChange && pageThemeChange("blue");

    return () => {
      pageThemeChange && pageThemeChange(undefined);
    };
  }, []);

  useEffect(() => {
    handleScroll();
    document.body.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, [slide]);

  const handleScroll = () => {
    const banner = document.getElementById("banner")?.getBoundingClientRect();
    const padding = 48;
    if (banner) {
      Math.abs(banner.y - padding) >= banner.height
        ? pageThemeChange && pageThemeChange(undefined)
        : pageThemeChange && pageThemeChange("blue");
    } else if (bluePages.includes(slide)) {
      const page = document
        .getElementById("civileoPage")
        ?.getBoundingClientRect();
      if (page) {
        Math.abs(page.y - padding) >= page.height
          ? pageThemeChange && pageThemeChange(undefined)
          : pageThemeChange && pageThemeChange("blue");
      }
    } else {
      pageThemeChange && pageThemeChange(undefined);
    }
  };

  const handlePrevious = () => {
    if (slide > 0) {
      setSlideTransition("previous");
      setTimeout(() => {
        const newSlide = slide - 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
      }, 300);
    }
  };

  const handleNext = () => {
    if (slide < nOfSlides) {
      setSlideTransition("next");
      setTimeout(() => {
        const newSlide = slide + 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
      }, 300);
    }
  };

  return (
    <>
      <PageTitle subtitle={t(`civileo_page_title`)} />
      <PageWrapper
        animation={pageChange}
        background={bluePages.includes(slide) ? "#013CFF" : undefined}
      >
        {slide === 0 ? (
          <SlideTransitionWrapper slideChange={slideTransition}>
            <ContentWrapperWithoutPadding>
              <Banner>
                {background && (
                  <BannerBackground fluid={background.childImageSharp.fluid} />
                )}
                {logo && <BannerLogo fluid={logo.childImageSharp.fluid} />}
              </Banner>
              <ContentWrapperPadding>
                <Title>{t(`civileo_title`)}</Title>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(descriptions[0]),
                  }}
                />
              </ContentWrapperPadding>
            </ContentWrapperWithoutPadding>
          </SlideTransitionWrapper>
        ) : slide === 1 ? (
          <SlideTransitionWrapper slideChange={slideTransition}>
            <ContentWrapper>
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(descriptions[1]),
                }}
              />
            </ContentWrapper>
          </SlideTransitionWrapper>
        ) : slide === 2 ? (
          <SlideTransitionWrapper slideChange={slideTransition}>
            <ContentWrapper>
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(descriptions[2]),
                }}
              />
            </ContentWrapper>
          </SlideTransitionWrapper>
        ) : (
          bluePages.includes(slide) && (
            <SlideTransitionWrapper slideChange={slideTransition}>
              <BlueWrapper>
                <div>
                  <Title
                    dangerouslySetInnerHTML={{
                      __html: fixConjunctions(t(`civileo_sideTitle`)),
                    }}
                    color="#FFFFFF"
                  />
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: fixConjunctions(descriptions[slide]),
                    }}
                    color="#FFFFFF"
                  />
                </div>
              </BlueWrapper>
            </SlideTransitionWrapper>
          )
        )}
        <ArrowsWrapper
          arrowColor={[3, 4].includes(slide) ? "#FFFFFF" : undefined}
        >
          <LeftArrowWrapper
            slideChange={slide === 1 ? slideTransition : undefined}
          >
            {slide > 0 && <ArrowLeft onClick={handlePrevious} />}
          </LeftArrowWrapper>
          <RightArrowWrapper
            slideChange={slide === nOfSlides - 1 ? slideTransition : undefined}
          >
            {slide < nOfSlides && <ArrowRight onClick={handleNext} />}
          </RightArrowWrapper>
        </ArrowsWrapper>
      </PageWrapper>
      <VileoApp pageChange={pageChange} />
    </>
  );
};

const imagesQuery = graphql`
  {
    logo: file(name: { eq: "logo" }, relativeDirectory: { eq: "civileo" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    background: file(
      name: { eq: "logoBackground" }
      relativeDirectory: { eq: "civileo" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default CivileoMobilePage;
