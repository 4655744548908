import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  SlideTransitionWrapper,
  RowWrapper,
  MobileViewWrapper,
  MobileView,
  ContentWrapper,
  Title,
  Description,
  DescriptionTitle,
  DownloadIconsWrapper,
  ArrowsWrapper,
  LeftArrowWrapper,
  RightArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../../common/Scroll";
import DownlaodAppStore from "-!svg-react-loader!../../../../../../../assets/images/downloadAppStore.svg";
import DownloadGooglePlay from "-!svg-react-loader!../../../../../../../assets/images/downloadGooglePlay.svg";
import ArrowLeft from "-!svg-react-loader!../../../../../../../assets/images/arrowLeft.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../../assets/images/arrowRight.svg";

interface VileoProps {
  pageChange: PageChange;
}

const VileoMobilePage: React.FC<VileoProps> = ({ pageChange }) => {
  const { t } = useTranslation("rnd");
  const data = useStaticQuery(imageQuery);
  const descriptions = (t(`vileo_descriptions`) as unknown) as string[];
  const nOfSlides = 1;

  const [slide, setSlide] = useState<number>(
    pageChange === "previous" ? nOfSlides : 0
  );
  const [slideTransition, setSlideTransition] = useState<PageChange>();

  const handlePrevious = () => {
    if (slide > 0) {
      setSlideTransition("previous");
      setTimeout(() => {
        setSlideTransition(undefined);
        setSlide(slide - 1);
      }, 300);
    }
  };

  const handleNext = () => {
    if (slide < nOfSlides) {
      setSlideTransition("next");
      setTimeout(() => {
        setSlideTransition(undefined);
        setSlide(slide + 1);
      }, 300);
    }
  };

  return (
    <PageWrapper animation={pageChange}>
      <RowWrapper>
        {slide === 0 ? (
          <SlideTransitionWrapper slideChange={slideTransition}>
            <MobileViewWrapper>
              <MobileView
                fluid={data.file && data.file.childImageSharp.fluid}
              />
            </MobileViewWrapper>
            <ContentWrapper>
              <Title
                dangerouslySetInnerHTML={{ __html: t(`vileo_mobileTitle`) }}
              />
              <Description>
                <DescriptionTitle>
                  {t(`vileo_descriptions_title`)}
                </DescriptionTitle>
                {t(`vileo_descriptions_mobile1`)}
              </Description>
            </ContentWrapper>
          </SlideTransitionWrapper>
        ) : (
          <SlideTransitionWrapper slideChange={slideTransition}>
            <ContentWrapper>
              <Description>{t(`vileo_descriptions_mobile2`)}</Description>
              <DownloadIconsWrapper>
                <a
                  href="javascript:void(0)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DownlaodAppStore />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.oke.vileo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DownloadGooglePlay />
                </a>
              </DownloadIconsWrapper>
            </ContentWrapper>
          </SlideTransitionWrapper>
        )}
      </RowWrapper>
      <ArrowsWrapper>
        <LeftArrowWrapper
          slideChange={slide === 1 ? slideTransition : undefined}
        >
          {slide > 0 && <ArrowLeft onClick={handlePrevious} />}
        </LeftArrowWrapper>
        <RightArrowWrapper
          slideChange={slide === nOfSlides - 1 ? slideTransition : undefined}
        >
          {slide < nOfSlides && <ArrowRight onClick={handleNext} />}
        </RightArrowWrapper>
      </ArrowsWrapper>
    </PageWrapper>
  );
};

const imageQuery = graphql`
  {
    file(name: { eq: "vileoMobileView" }) {
      name
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default VileoMobilePage;
