import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideToLeft,
  slideToRight,
} from "../../../../../../../utils/animations";
import { PageChange } from "../../../../../../common/Scroll";
import {
  H4,
  SUBTITLE_2,
  TEXT_BODY_2,
} from "../../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 112px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

interface SlideTransitionProps {
  slideChange: PageChange;
}

export const SlideTransitionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  animation: ${(props: SlideTransitionProps) =>
    props.slideChange
      ? css`
          ${props.slideChange === "next"
            ? slideToLeft
            : slideToRight} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const LogoWrapper = styled.div`
  width: 100%;
`;

export const RowWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileViewWrapper = styled.div`
  width: 95%;
  height: 24vh;
  margin: 0 auto;
  padding: 0 16px;

  @media ${device.mobileS} {
    height: auto;
  } ;
`;

export const MobileView = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  width: 100%;
  height: 100%;

  img {
    object-fit: contain !important;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  ${H4}
  margin: 24px 0;
  color: #013cff;
  text-align: left;

  a,
  a:visited,
  a:link {
    color: #013cff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  color: #151515;
  white-space: pre-line;
`;

export const DescriptionTitle = styled.span`
  ${SUBTITLE_2}
`;

export const DownloadIconsWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  a {
    width: calc(50%);
    height: auto;
    box-sizing: unset;

    &:first-of-type {
      margin-right: 12px;
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
`;

export const ArrowsWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 8px 24px;
  display: grid;
  grid-template-columns: repeat(50%, 2);
`;

export const LeftArrowWrapper = styled.div`
  grid-column: 1;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "previous" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;

export const RightArrowWrapper = styled.div`
  grid-column: 2;
  margin-left: auto;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "next" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;
