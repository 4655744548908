import styled, { css } from "styled-components";
import Img from "gatsby-image";

import {
  fadeIn,
  fadeOut,
  slideToLeft,
  slideToRight,
} from "../../../../../../utils/animations";
import { PageChange } from "../../../../../common/Scroll";
import { H4, TEXT_BODY_2 } from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
  background?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  background: ${(props: PageProps) => props.background || "#FFFFFF"};
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
  transition: background 300ms;
`;

interface SlideTransitionProps {
  slideChange: PageChange;
}

export const SlideTransitionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  animation: ${(props: SlideTransitionProps) =>
    props.slideChange
      ? css`
          ${props.slideChange === "next"
            ? slideToLeft
            : slideToRight} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 33vh;
`;

export const BannerBackground = styled(Img)`
  position: absolute !important;
  top: 72px;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BannerLogo = styled(Img)`
  position: absolute !important;
  top: 80%;
  left: 50%;
  width: 50%;
  height: 60%;
  transform: translate(-50%, -50%);

  img {
    object-fit: contain !important;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  height: calc(var(--vh, 1vh) * 100);
  padding: 112px 24px 0 24px;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapperWithoutPadding = styled.div`
  flex: 1;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
`;

export const ContentWrapperPadding = styled.div`
  padding: 72px 24px 32px 24px;
  display: flex;
  flex-direction: column;
`;

interface TextProps {
  color?: string;
}

export const Title = styled.h1`
  ${H4}
  margin: 24px 0;
  color: ${(props: TextProps) => props.color || "#013CFF"};
  white-space: pre;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  margin: 0;
  color: ${(props: TextProps) => props.color || "#151515"};
  white-space: pre-line;

  a,
  a:visited,
  a:link {
    color: #013cff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const BlueWrapper = styled.div`
  flex: 1;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 112px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #013cff;
`;

interface ArrowsWrapperProps {
  arrowColor?: string;
}

export const ArrowsWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 72px 24px 24px 24px;
  display: grid;
  grid-template-columns: repeat(50%, 2);

  svg > rect {
    ${(props: ArrowsWrapperProps) =>
      props.arrowColor && `fill: ${props.arrowColor} !important;`};
  }
`;

export const LeftArrowWrapper = styled.div`
  grid-column: 1;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "previous" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;

export const RightArrowWrapper = styled.div`
  grid-column: 2;
  margin-left: auto;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "next" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;
